import React from 'react';

const List = () => {
    return (
        <div>
            <h2>Art Pieces List</h2>
            <ul>
                <li>Art Piece 1 - Hull, UK</li>
                <li>Art Piece 2 - Grimsby, UK</li>
                {/* Add more list items here */}
            </ul>
        </div>
    );
};

export default List;
