import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // General styles
import App from './App';
import 'leaflet/dist/leaflet.css'; // Leaflet map styles
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
