import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ArtMap from './ArtMap';
import About from './About';
import List from './List';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>John Freear Art Map</h1>
                    <nav>
                        <ul>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/list">List</Link></li>
                            <li><Link to="/">Map</Link></li>
                        </ul>
                    </nav>
                </header>

                <Routes>
                    <Route path="/" element={<ArtMap />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/list" element={<List />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
