import React from 'react';
import profile from './profile_art_map.jpg';


const About = () => {
    return (
        <div>
            <h2>About John Freear</h2>
            <p>This is an art map showcasing various art pieces around the Cleethorpes and Grimsby areas.</p>
            <img src={profile} alt="John"/>
        </div>
    );
};

export default About;
