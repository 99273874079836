import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import starryNightIcon from './starry-night.png'; // Adjust the path if necessary
import monaLisa from './mona-lisa.png'
import qJubilee from './art1.jpg'

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// Art pieces data
const artPieces = [
    {
        id: 1,
        title: "1977 Queens Jubilee Mural",
        description: "John Freear's Silver Jubilee Ceramic Tile Mural (1977)",
        position: [53.56670687879719, -0.08691424942848994], // Grimsby, UK
        fullDetails: {
            image: starryNightIcon,
            location: "Riverhead shopping precinct (now Freshney Place), Grimsby",
            datesCreated: "1977",
            fullDescription: `
                <strong>Artist:</strong> John Freear<br/>
                <strong>Location:</strong> Riverhead shopping precinct (now Freshney Place), Grimsby<br/>
                <strong>Year:</strong> 1977<br/>
                <strong>Medium:</strong> Ceramic tiles<br/>
                <strong>Commissioned by:</strong> Grimsby Council for Queen Elizabeth II's Silver Jubilee<br/><br/>
                This striking ceramic tile mural was a vibrant addition to Grimsby's urban landscape, created to commemorate Queen Elizabeth II's Silver Jubilee in 1977. John Freear, a local artist from Barry Avenue in Grimsby, was commissioned to design and paint this £1,000 piece, which showcased his skill in working with ceramic tiles as a medium.<br/><br/>
                <strong>Artistic Elements:</strong><br/>
                The mural likely incorporated themes related to the Queen's 25-year reign.<br/>
                Ceramic tiles allowed for a durable, colorful, and textured surface.<br/>
                The piece was designed to enhance the shopping precinct's aesthetic appeal.<br/><br/>
                <strong>Historical Significance:</strong><br/>
                The artwork represented a moment of national celebration, connecting Grimsby to the broader jubilee festivities across the United Kingdom. Its placement in a public shopping area ensured high visibility and community engagement with the piece.<br/><br/>
                <strong>Current Status:</strong><br/>
                Sadly, this piece of local history has been obscured from public view. The mural is now hidden behind two sales kiosks, awaiting rediscovery by future generations.
            `,
            artImage: qJubilee, // Image specific to the mural
        }
    },
    {
        id: 2,
        title: "Art Piece 2",
        description: "Description for Art Piece 2",
        position: [53.560342, -0.031602], // Grimsby, UK
        fullDetails: {
            image: monaLisa,
            location: "Grimsby, UK", // Correct location for Art Piece 2
            datesCreated: "2025",
            fullDescription: "Full description with more details about Art Piece 2.",
        },
    }
];

// Create a custom icon using the imported image
const customIcon = new L.Icon({
    iconUrl: starryNightIcon,
    iconSize: [38, 50], // Adjust the size to your preference
    iconAnchor: [19, 50], // The point of the icon which will correspond to marker's location
    popupAnchor: [0, -50] // The point from which the popup should open relative to the iconAnchor
});

// Define the bounds for the map (around Grimsby and Hull, UK)
const bounds = [
    [53.0, -1.5], // Southwest coordinates (bottom-left corner) with more left room
    [54.5, 1.0],  // Northeast coordinates (top-right corner) with more right room
];

// Overlay component
const Overlay = ({ details, onClose }) => {
    return (
        <div className={`overlay show`}>
            <button className="close-btn" onClick={onClose}>X</button>
            <img src={details.artImage} alt={details.title} style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
            <h2>{details.title}</h2>
            {/* Add a scrollable container */}
            <div className="overlay-content">
                <div dangerouslySetInnerHTML={{ __html: details.fullDescription }} />
                <p><strong>Location:</strong> {details.location}</p>
                <p><strong>Dates Created:</strong> {details.datesCreated}</p>
            </div>
        </div>
    );
};


const ArtMap = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedArt, setSelectedArt] = useState(null);

    const openOverlay = (art) => {
        setSelectedArt(art);
        setShowOverlay(true);
    };

    const closeOverlay = () => {
        setShowOverlay(false);
        setSelectedArt(null);
    };

    return (
        <div style={{ margin: '0 auto', width: '90%', position: 'relative' }}>
            <MapContainer
                center={[53.560342, -0.031603]}
                zoom={12}
                minZoom={10}
                maxZoom={16}
                style={{ height: "80vh", width: "100%" }}
                maxBounds={bounds}
                maxBoundsViscosity={0.5}
            >
                <TileLayer
                    url={`https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=${accessToken}`}
                    attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                    tileSize={512}
                    zoomOffset={-1}
                />

                {artPieces.map(piece => (
                    <Marker key={piece.id} position={piece.position} icon={customIcon}>
                        <Popup>
                            <h3>{piece.title}</h3>
                            <p>{piece.description}</p>
                            <button onClick={() => openOverlay(piece)}>Read More</button>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>

            {showOverlay && selectedArt && (
                <Overlay details={selectedArt.fullDetails} onClose={closeOverlay} />
            )}
        </div>
    );
};

export default ArtMap;
